.SelectFilter {
    &__Label {
        line-height: 2.3rem;

        label {
            margin-bottom: 0 !important;
        }
    }

    &__Select {
        text-align: center;
        margin-left: 1rem;
        &:disabled {
            background-color: #fff;
            cursor: not-allowed;
        }
    }
}

.form-select:focus {
    box-shadow: none;
}