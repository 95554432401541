body {
  background: #F2F2F2;
  height: auto !important;
}

.rdw-editor-wrapper {
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 3px;
}

.btn-danger {
  background-color: #C20E1A !important;
  border-color: #C20E1A !important;
}

a {
  color: #C20E1A;
  text-decoration: none;
}