.Campaigns {
  &__New svg {
    font-size: 24px;
    margin-left: 0.5rem;
  }

  &__ChangeClient svg {
    font-size: 24px;
    margin-right: 0.5rem;
  }

  .nav-link {
    color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
  }

  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #000000;
    background-color: transparent;
    border-color: transparent;
    border-bottom-color: #dd121b;
    border-bottom-width: thick;
  }

  .btn-link {
    color: #000000;
    text-decoration: none;
  }

  .btn-check:focus + .btn,
  .btn:focus {
    box-shadow: none;
  }

  .btn-check:focus + .btn-outline-dark,
  .btn-outline-dark:focus {
    box-shadow: none;
  }

  .form-select:focus {
    border-color: #212529;
    box-shadow: none;
  }
}
