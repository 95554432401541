.DashboardCards{
    &__Content {
        border-left: 0.5rem;
        // border-color: turquoise; //Cambia según cliente
        border-style: none none none solid;
    }
    &__Container {
        max-width: 100%;
    }
    &__HeadInvestment {
        width: 8rem;
    }
    &__HeadPerformance {
        font-size: 14px;
    }
    &__Head {
        width: 6rem;
    }
    &__Percentage {
        display: flex;
    }
    &__Cost {
        margin: 2px 0;
    }    
    &__IconArrow {
        margin-bottom: 0.5rem;
    }
}

.accordion-button:not(.collapsed) {
    color: #212529;
    background-color: #E9FCF8;
}

.form-check-input:checked {
    background-color: black;
    border-color: #D9D9D9;
}

.accordion-button:focus {
    box-shadow: none;
}