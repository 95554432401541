.UserCard {
  &__Item {
    position: relative;
    align-items: center;
    width: 100%;
    padding: 1rem 1.25rem;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    background-color: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    border-left: 1px solid rgba(0, 0, 0, 0.125);
    border-right: 1px solid rgba(0, 0, 0, 0.125);
    overflow-anchor: none;
  }

  &__ProfilePic {
    width: 36px;
    height: 36px;
    border-radius: 36px;
  }
}
