.ClientIconCard {
  &__Card {
    width: 85px;
    height: 85px;
    background: #ffffff;
    border-top: 1px solid rgba(0, 0, 0, 0.2) !important;
    border-left: 1px solid rgba(0, 0, 0, 0.2) !important;
    border-right: 1px solid rgba(0, 0, 0, 0.2) !important;
    border-radius: 10px;

    &__Img {
      height: 80px;

      img {
        max-width: 80px;
        max-height: 80px;
      }
    }
  }
}
