.Home {
    &__ListGroup {
        .list-group-item {
            display: flex;
            div {
                &:first-child {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    svg {
                        max-width: 4.75rem;
                        max-height: 4.75rem;
                    }
                    &.iconFC0 svg rect{ fill: #fc0; }
                    &.icon00F svg rect { fill: #0652DD; }
                }
                &:last-child {
                    span.badge {
                        position: absolute;
                        right: 0.75rem;
                    }
                    strong, small {
                        display: block;
                    }
                    strong {
                        width: calc(100% - 5.5rem);
                    }
                    padding-left: 1rem;
                    flex-grow: 1;
                }
            }
        }
    }

    &__Checkbox {
        margin-top: 2rem;
    }

    &__Header {
        margin-left: 10px;
        margin-right: 1px;
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
    }
}