.RequestAccess {
  &__MainRow{
    height: 100vh;
  }
  &__Background {
    background: url('https://cdn-media-api-web.nyc3.digitaloceanspaces.com/contingente/LoginImageBackground.jpeg');
    background-size: cover;
    background-position: center center;
  }
  &__Form {
    background: #FFF;
    &__Back {
      position: absolute;
      margin-top: 24px;
      .isMobile & {
        position: initial;
        display: inline-block;
        text-align: center;
        width: 100%;
        line-height: 48px;
        margin-top: 8px;
      }
    }
    &__Col {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      p {
        text-align: center;
        user-select: none;
      }
      &__Logotipo {
        max-width: 280px;
        margin-bottom: 48px;
      }
      h2 {
        user-select: none;
        font-weight: 200;
        text-align: center;
      }
      form {
        width: 90%;
        .isMobile & {
          width: 100%;
        }
        & > .row {
          margin-bottom: 32px;
          & > .col:last-child {
            text-align: right;
          }
        }
        button.btn {
          margin-top: 32px;
        }
      }
    }
  }
}