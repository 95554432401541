.ClientCard {

  &__Card {
    width: 160px;
    height: 190px;
    background: #FFFFFF;
    border-top: 1px solid rgba(0, 0, 0, 0.2) !important;
    border-left: 1px solid rgba(0, 0, 0, 0.2) !important;
    border-right: 1px solid rgba(0, 0, 0, 0.2) !important;
    border-radius: 10px;

    &__Buttons {
      height: 36px;
    }

    &__Link {
      color: #D9D9D9;
    }

    &__Img {
      height: 100px;

      img {
        max-width: 128px;
        max-height: 100px;
      }
    }

    &__Body {
      padding: 0;
    }

    &__Title {
      color: #636E72;
      font-size: 14px;
      padding: 0 1rem;
      margin-bottom: 0.2rem;
    }

    .btn-outline-secondary:hover {
      color: #6c757d;
      background-color: transparent;
    }

    .btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
      box-shadow: none;
    }
  }

}