.ClientForm {
  padding: 0;

  &__Card {
    box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    padding: 1rem 1rem 0 1rem;
  }

  &__InputColor {
    height: 38px;
  }

  .form-control:focus {
    border-color: #212529;
    box-shadow: none;
  }

  .form-check-input:checked {
    background-color: #c20e1a !important;
    border-color: #c20e1a !important;
  }

  .form-check-input:focus {
    box-shadow: none;
    border-color: rgba(0, 0, 0, 0.25);
  }
}
