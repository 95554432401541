.CampaignForm {
  padding: 0;

  &__Card {
    box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    padding: 1rem 1rem 0 1rem;
  }

  .form-control:focus {
    border-color: #212529;
    box-shadow: none;
  }

  .form-select:focus {
    border-color: #212529;
    box-shadow: none;
  }

  .form-check-input:checked {
    background-color: #c20e1a !important;
    border-color: #c20e1a !important;
  }

  .form-check-input:focus {
    box-shadow: none;
    border-color: rgba(0, 0, 0, 0.25);
  }

  .form-label {
    font-weight: 500;
  }
  /* Ocultar flechas de campo number en Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
