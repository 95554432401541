.DateFilter {
    &__Label {
        line-height: 2.3rem;
        margin-left: 1rem;

        label {
            margin-bottom: 0 !important;
        }
    }

    &__Input {
        border: none;
        text-align: center;
        margin-left: 1rem;
        &:disabled {
            background-color: #FFF;
        }
    }
}