.Configuration {
  .nav-link {
    color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
  }

  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #000000;
    background-color: transparent;
    border-color: transparent;
    border-bottom-color: #dd121b;
    border-bottom-width: thick;
  }

  .btn-check:focus + .btn-outline-dark,
  .btn-outline-dark:focus {
    box-shadow: none;
  }
}
