.AdsTable {
  &__Nav {
    .nav-link {
      background-color: transparent;
      color: rgba($color: #000000, $alpha: 0.5);
      border: none;
      border-bottom: 2px solid rgba($color: #000000, $alpha: 0.1);
    }
    .nav-link.active {
      background-color: transparent;
      color: rgba($color: #000000, $alpha: 0.75);
      font-weight: 900;
      border: none;
      border-bottom: 2px solid rgba($color: #e30613, $alpha: 1);
    }
  }
}