.CampaignPlatformCard {
 &__Item {
   position: relative;
   align-items: center;
   width: 100%;
   padding: 1rem 1.25rem;
   font-size: 1rem;
   color: #212529;
   text-align: left;
   background-color: #fff;
   border-bottom: 1px solid rgba(0, 0, 0, 0.125);
   border-left: 1px solid rgba(0, 0, 0, 0.125);
   border-right: 1px solid rgba(0, 0, 0, 0.125);
   overflow-anchor: none;
 }

 &__Button {
   color: #D9D9D9;

   svg {
     vertical-align: text-top;
   }
 }

 .btn {
   font-size: 20px;
 }

 .btn-outline-secondary:hover {
   color: #6c757d;
   background-color: transparent;
 }

 .btn-check:focus + .btn-outline-secondary,
 .btn-outline-secondary:focus {
   box-shadow: none;
 }
}