.FormFilter{
    &__Buttons {
        display: flex;
        justify-content: flex-end;
        &__Reset {
            text-decoration: underline;
            svg {
                margin-left: 0.25rem;
            }
            &:hover {
                color: #E30613;
            }
        }
        &__Submit {
            background-color: #FFF;
        }
    }
}